import { DatePipe } from '@angular/common';
import { ObjectPropertyFormat } from '@newroom-connect/library/enums/object';
import { FileHelper } from '@newroom-connect/library/helpers/file';
import { IObjectProperty } from '@newroom-connect/library/interfaces/object';

export class ObjectHelper {
  private static readonly datePipe = new DatePipe('en-US');

  /**
   * Extract a property value from a given object by the given property key. It is possible to use the dot notation to extract a nested property of an object.
   *
   * @param objectToExtractFrom The object to extract the property value from by the given `propertyKey`.
   * @param propertyKey The key of the property to extract the value from.
   *
   * @returns The value of the object property.
   */
  public static extractProperty(objectToExtractFrom: unknown, propertyKey: string): unknown {
    const propertyNameParts = propertyKey.split('.');

    let objectPart: unknown = objectToExtractFrom;

    for (const propertyNamePart of propertyNameParts) {
      if (!objectPart || typeof objectPart !== 'object') {
        return undefined;
      }

      const propertyNamePartAsNumber = +propertyNamePart;

      if (!isNaN(propertyNamePartAsNumber) && Array.isArray(objectPart) && propertyNamePartAsNumber < objectPart.length) {
        objectPart = objectPart[propertyNamePartAsNumber];
      } else if (isNaN(propertyNamePartAsNumber) && Object.keys(objectPart).includes(propertyNamePart)) {
        objectPart = (objectPart as Record<string, unknown>)[propertyNamePart];
      } else {
        return undefined;
      }
    }

    return objectPart;
  }

  /**
   * Extract a list of properties list from a given object by the given property keys. It is possible to use the dot notation to extract a nested property of an object.
   * It is also possible to apply a format to the extracted property value. Currently, the following formats are supported:
   *
   * - TEXT: No format is applied to the extracted property value.
   * - DATE: The extracted property value is formatted as a date string 'yyyy-MM-dd hh:mm'.
   * - FILE_SIZE: The extracted property value is formatted as a file size string.
   *
   * @param objectToExtractFrom The target object to extract the properties from.
   * @param propertiesToExtract The name of the properties to extract.
   *
   * @returns A list of extracted properties as a string separated by the given `delimiter`. Default delimiter is ','.
   */
  public static extractProperties<T = unknown>(objectToExtractFrom: unknown, propertiesToExtract: IObjectProperty[]): T[] {
    const result: T[] = [];

    propertiesToExtract.forEach((propertyToExtract: IObjectProperty) => {
      const value = ObjectHelper.extractProperty(objectToExtractFrom, propertyToExtract.key) as T;

      if (!value) {
        return;
      }

      // Transform the extracted value, if a format is present on the property to extract.
      if (propertyToExtract.format) {
        switch (propertyToExtract.format) {
          case ObjectPropertyFormat.DATE: {
            const date = this.datePipe.transform(String(value), 'yyyy-MM-dd');

            if (typeof date === 'string') {
              result.push(date as T);
            }
            break;
          }
          case ObjectPropertyFormat.DATETIME: {
            const date = this.datePipe.transform(String(value), 'yyyy-MM-dd hh:mm');

            if (typeof date === 'string') {
              result.push(date as T);
            }
            break;
          }
          case ObjectPropertyFormat.FILE_SIZE: {
            const size = FileHelper.toFileSizeString(Number(value));

            if (typeof size === 'string') {
              result.push(size as T);
            }
            break;
          }
          default:
            result.push(value);
            break;
        }
      }
    });

    return result;
  }
}
