import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ChipComponent } from '@newroom-connect/library/components/chip';
import { IconComponent } from '@newroom-connect/library/components/icon';
import { LoadingComponent } from '@newroom-connect/library/components/loading';
import { TooltipComponent } from '@newroom-connect/library/components/tooltip';
import { ExtractPropertyPipe } from '@newroom-connect/library/pipes/extract-property';

import { NavigatorComponent } from './navigator.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IconComponent,
    TooltipComponent,
    ChipComponent,
    LoadingComponent,
    ExtractPropertyPipe
  ],
  declarations: [
    NavigatorComponent
  ],
  exports: [
    NavigatorComponent
  ]
})
export class NavigatorComponentModule {}
