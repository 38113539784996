import { Pipe, PipeTransform } from '@angular/core';
import { TranslationService } from '@newroom-connect/library/services/translation';

@Pipe({
  standalone: true,
  name: 'translate'
})
export class TranslatePipe implements PipeTransform {
  /**
   * @constructor
   *
   * @param translationService
   */
  constructor(
    private readonly translationService: TranslationService
  ) {}

  /**
   * Translate a text by the given translation key from the i18n texts.
   * Uses the internal `TranslationService` for translation.
   *
   * @param translationKey The key of the translation to fetch and translate.
   * @param substitute
   *
   * @returns The translated value of the requested translation by key.
   */
  public transform(translationKey: string, substitute?: string | Record<string, unknown>): string {
    return this.translationService.translate(translationKey, substitute);
  }
}
