import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl } from '@angular/forms';
import { NavigationEnd, Router } from '@angular/router';
import { growAnimation } from '@newroom-connect/library/animations/grow';
import { ActionRole } from '@newroom-connect/library/enums/action-role';
import { ObjectHelper } from '@newroom-connect/library/helpers/object';

@Component({
  selector: 'nrc-virtual-studio-navigator',
  templateUrl: './navigator.component.html',
  animations: [growAnimation]
})
export class NavigatorComponent<T = any> implements OnInit, OnChanges {
  @Input() public options: T[] = [];
  @Input() public valueProperty?: string;
  @Input() public labelProperty?: string;
  @Input() public excludedRoutes?: string[];
  @Input() public selectedOption?: T | null;
  @Input() public placeholder = ' ';
  @Input() public label: string | unknown = '';

  @Output() public optionChangedEvent = new EventEmitter<T>();

  public showOptions = false;
  public optionsFiltered: T[] = [];
  public currentRoute?: string;
  public actionRole = ActionRole;
  public lastPartOfRoute = signal<string | null>(null);

  public searchInputFormControl = new FormControl<string>('');

  /**
   * @constructor
   *
   * @param router
   */
  constructor(
    private readonly router: Router
  ) {
    this.searchInputFormControl.valueChanges.pipe(takeUntilDestroyed()).subscribe(searchInput => {
      this.optionsFiltered = this.options.filter(option => {
        const optionLabel = ObjectHelper.extractProperty(option, this.labelProperty ?? '') as string;

        return optionLabel.toLowerCase().includes(searchInput?.toLowerCase() ?? '');
      });
    });

    this.router.events.pipe(takeUntilDestroyed()).subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.lastPartOfRoute.set(this.extractFirstPartOfRoute());
      }
    });
  }

  /**
   *
   */
  public ngOnInit(): void {
    this.optionsFiltered = this.options;
  }

  /**
   *
   * @param changes
   */
  public ngOnChanges(changes: SimpleChanges): void {
    // Set the filtered options, if options have been received.
    if (changes['options'].previousValue?.length === 0 && changes['options'].currentValue?.length > 0) {
      this.optionsFiltered = changes['options'].currentValue;
    }
  }

  /**
   *
   * @param value
   */
  public writeValue(value: any): void {
    this.selectedOption = value;
  }

  /**
   *
   * @param option
   */
  public selectOption(option: any): void {
    this.showOptions = false;
    this.selectedOption = option;

    if (this.lastPartOfRoute()) {
      const currentUrl = this.router.url;

      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.router.navigate([currentUrl]);
      });
    }

    this.optionChangedEvent.emit(option);
  }

  /**
   * Extract the first part of the current route.
   * Parts are splitted by the `/` separator.
   *
   * @returns First part of the current route or `null`, if an excluded route starts with the first part of the route.
   *
   * @example "/projects/dc111e53-826c-4b03-8c5b-7dbfae2fb17c/areas" -> "areas"
   */
  private extractFirstPartOfRoute(): string | null {
    const urlParts = this.router.url.split('/');

    if (urlParts.length < 2) {
      return null;
    }

    const firstPart = urlParts[1];

    if (this.excludedRoutes?.some(excludedRoute => firstPart.startsWith(excludedRoute))) {
      return null;
    }

    return firstPart;
  }
}
