import { FileType } from '@newroom-connect/library/enums/file-type';
import { ObjectPropertyFormat } from '@newroom-connect/library/enums/object';
import { ArrayHelper } from '@newroom-connect/library/helpers/array';
import { IFile } from '@newroom-connect/library/interfaces/file';
import { IObjectProperty } from '@newroom-connect/library/interfaces/object';

export class FileHelper {
  /**
   * Converts a given number of bytes into a human-readable format with the
   * appropriate unit (e.g., KB, MB, GB).
   *
   * @param bytes The amount of bytes to convert.
   * @param decimals The number of decimal places to show after comma.
   *
   * @returns A string that represents the given number of bytes in a human-readable format. The string
   * includes the converted value in the appropriate unit (e.g., KB, MB, GB, etc.) with the specified
   * number of decimal places.
   *
   * @example `bytes`: 2034, `decimals`: 2 -> "1.99 MB"
   */
  public static toFileSizeString(bytes = 0, decimals = 2): string {
    if (bytes === 0) {
      return '0 Bytes';
    }

    const k = 1024,
      dm = decimals || 2,
      sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
      i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
  }

  /**
   *
   * @param response
   * @param filename
   * @param mimetype
   */
  public static downloadFile(response: BlobPart, filename: string, mimetype: string): void {
    const blob = new Blob([response], { type: mimetype });

    const downloadLink = document.createElement('a');

    downloadLink.href = URL.createObjectURL(blob);

    downloadLink.setAttribute('download', filename);
    downloadLink.setAttribute('type', 'hidden');

    document.body.appendChild(downloadLink);

    downloadLink.click();

    downloadLink.remove();
  }

  /**
   * Extract the filename from a given "Content-Disposition" header.
   *
   * @param contentDispositionHeader The "Content-Disposition" header to extract the filename from.
   *
   * @returns The extracted filename from the "Content-Disposition" header.
   */
  public static extractFilenameFromContentDispositionHeader(contentDispositionHeader: string): string {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_, filenamePart] = contentDispositionHeader.split('filename=');

    return filenamePart.substring(0, filenamePart.length);
  }

  /**
   * Extracts the mimetype group from a given mimetype.
   *
   * @param mimetype The input mimetype (e.g., 'image/jpeg').
   *
   * @returns The extracted mimetype group or undefined if not found.
   *
   * @example 'application/pdf' -> 'application'; 'image/jpeg' -> 'image'
   */
  public static extractMimeTypeGroup(mimetype: string): string | undefined {
    const parts = mimetype.split('/');

    if (parts.length === 2) {
      return parts[0];
    }

    return undefined;
  }

  /**
   *
   * @param file
   *
   * @returns True if the file is referenced by an area.
   * TODO: This has to be updated once the file area translations are handled.
   */
  public static isFileReferenced(file: IFile): boolean {
    return ArrayHelper.isNotEmpty((file as any).areaTranslations);
  }

  /**
   * Check, if a given file is already converted to a cubemap.
   * A cubemap file has file formats including six formats of type "CUBEMAP_FACE".
   *
   * @param file
   *
   * @returns Boolean indicating, if the file is already converted to a cubemap.
   */
  public static isFileCubemap(file: IFile): boolean {
    return ArrayHelper.isNotEmpty(file.formats) && file.formats!.filter(format => format.type.startsWith(FileType.CUBEMAP_FACE)).length >= 6;
  }

  /**
   * Extracts a specific file format from the given file object.
   *
   * @param file
   * @param format
   *
   * @returns The file object of the specified format if found, otherwise undefined.
   */
  public static extractFileFormat(file: IFile, format: FileType): IFile | undefined {
    if (!ArrayHelper.isNotEmpty(file.formats)) {
      return undefined;
    }

    return file.formats!.find(fileFormat => fileFormat.type === format);
  }

  /**
   * Get the source URI for a given file.
   *
   * @param baseUrl The base URL appended to the beginning of the source URI.
   * @param file The file to get the source URI for.
   *
   * @returns The source URI for the given file.
   */
  public static getFileSourceURI(baseUrl: string, file?: IFile): string {
    if (!file) {
      return '';
    }

    return `${baseUrl}/projects/${file.projectId}/files/${file.id}/stream`;
  }

  /**
   * Generate default additional object properties to show e.g. In a modal for a file replacement operation..
   *
   * @returns
   */
  public static generateFileReplacementAdditionalProperties(): IObjectProperty[] {
    // The additional properties of the file to show to replace in the corresponding modal.
    return [
      { key: 'extension', format: ObjectPropertyFormat.TEXT },
      { key: 'createdAt', format: ObjectPropertyFormat.DATETIME },
      { key: 'size', format: ObjectPropertyFormat.FILE_SIZE }
    ];
  }
}
