import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IAnalyticsProviderPreset } from '@newroom-connect/library/interfaces/project';
import { EntityService } from '@newroom-connect/library/services/entity';
import { IAPIListResponse } from '@newroom-connect/library/interfaces/api-list-response';
import { IListOptions } from '@newroom-connect/library/interfaces/list';

@Injectable({
  providedIn: 'root'
})
export class ProjectAnalyticsService extends EntityService {
  protected searchableProperties: string[] = []; // Empty, since we don't want to search for anything.

  /**
   * Get all supported analytics providers presets.
   *
   * @param options
   * @returns Analytics provider presets.
   */
  public getProjectAnalyticsPresets(options?: IListOptions): Observable<IAPIListResponse<IAnalyticsProviderPreset>> {
    return super.list<IAnalyticsProviderPreset>('presets/projects/analytics-providers', undefined, options);
  }
}
