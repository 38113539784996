import { Pipe, PipeTransform } from '@angular/core';
import { ArrayHelper } from '@newroom-connect/library/helpers/array';

@Pipe({
  standalone: true,
  name: 'isNonEmptyArray'
})
export class IsNonEmptyArrayPipe implements PipeTransform {
  /**
   * Check if the given value is a non-empty array.
   *
   * @param value The value to check.
   *
   * @returns Boolean indicating if the value is an array and contains elements.
   */
  public transform(value: unknown): boolean {
    return ArrayHelper.isNotEmpty(value);
  }
}
