export class ArrayHelper {
  /**
   * Check if the given item is an array and not empty.
   *
   * @param item The item to check.
   *
   * @returns Boolean indicating if the given item is an array and not empty or not.
   */
  public static isNotEmpty(item: unknown): boolean {
    return Array.isArray(item) && item.length > 0;
  }

  /**
   *
   * @param array
   * @param fromIndex
   * @param toIndex
   *
   * @returns
   *
   * @throws
   */
  public static move<T>(array: T[], fromIndex: number, toIndex: number): T[] {
    if (toIndex >= array.length) {
      throw new Error('Unable to move item to index greater than array length');
    }

    const arrayCopy = [...array];

    arrayCopy.splice(toIndex, 0, arrayCopy.splice(fromIndex, 1)[0]);

    return arrayCopy;
  }
}
