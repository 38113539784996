import { NgModule } from '@angular/core';
import { RouterModule, Routes, TitleStrategy } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { LoginGuard } from './guards/login/login.guard';
import { AlredyLoggedInGuard } from './guards/already-logged-in/alredy-logged-in.guard';
import { IsProjectSelectedGuard } from './guards/is-project-selected/is-project-selected.guard';
import { PageTitleStrategy } from './strategies/page-title.strategy';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
    title: 'Home'
  },
  {
    path: 'signin',
    title: 'Sign-in',
    loadChildren: () => import('./pages/sign-in/sign-in.page.module').then(m => m.SignInPageModule),
    data: { animation: 'signinPage' },
    canActivate: [AlredyLoggedInGuard]
  },
  {
    path: 'signup',
    title: 'Sign-up',
    loadChildren: () => import('./pages/sign-up/sign-up.page.module').then(m => m.SignUpPageModule),
    data: { animation: 'signupPage' },
    canActivate: [AlredyLoggedInGuard]
  },
  {
    path: 'home',
    title: 'Home',
    loadChildren: () => import('./pages/home/home.page.module').then(m => m.HomePageModule),
    data: { animation: 'homePage' },
    canActivate: [LoginGuard]
  },
  {
    path: 'projects',
    title: 'Projects',
    loadChildren: () => import('./pages/project/project.page.module').then(m => m.ProjectPageModule),
    data: { animation: 'projectsPage' },
    canActivate: [LoginGuard]
  },
  {
    path: 'areas',
    title: 'Areas',
    loadChildren: () => import('./pages/area/area.page.module').then(m => m.AreaPageModule),
    data: { animation: 'areasPage' },
    canActivate: [LoginGuard, IsProjectSelectedGuard]
  },
  {
    path: 'files',
    title: 'Files',
    loadChildren: () => import('./pages/file/file.page.module').then(m => m.FilePageModule),
    data: { animation: 'filesPage' },
    canActivate: [LoginGuard, IsProjectSelectedGuard]
  },
  {
    path: 'legal',
    title: 'Legal',
    loadChildren: () => import('./pages/legal/legal.page.module').then(m => m.LegalPageModule),
    data: { animation: 'legalPage' }
  },
  {
    path: '404',
    title: '404',
    data: { animation: '404Page' },
    loadChildren: () => import('./pages/not-found/not-found.page.module').then(m => m.NotFoundPageModule)
  },
  {
    path: '**',
    redirectTo: '404',
    pathMatch: 'full'
  }
];

@NgModule({
  declarations: [],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule],
  providers: [
    {
      provide: TitleStrategy,
      useClass: PageTitleStrategy
    }
  ]
})
export class AppRoutingModule {}
