import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IconFillColor } from '@newroom-connect/library/components/icon';

@Component({
  selector: 'nrc-virtual-studio-header-item',
  templateUrl: './header-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderItemComponent {
  @Input({ required: true }) public icon = '';
  @Input({ required: true }) public tooltipText = '';
  @Input() public fillColor: IconFillColor = 'white';
  @Input() public isDisabled?: boolean;
}
