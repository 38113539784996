import { Injectable } from '@angular/core';
import {
  HttpEvent, HttpInterceptor, HttpHandler, HttpRequest
} from '@angular/common/http';
import { Observable, switchMap } from 'rxjs';
import { SessionService } from '@newroom-connect/library/services/session';
import { AuthService } from '@newroom-connect/library/services/auth';

/**
 * HTTP interceptor to apply an access token to each request, if an access token is available.
 */
@Injectable()
export class HttpAuthInterceptor implements HttpInterceptor {
  /**
   * @constructor
   *
   * @param sessionService
   * @param authService
   */
  constructor(
    private readonly sessionService: SessionService,
    private readonly authService: AuthService
  ) { }

  /**
   * Intercept an HTTP request where the session is refreshed if needed and
   * the access token is applied to the request in the `Authorization` header.
   *
   * @param request The request to be intercepted.
   * @param next The HTTP handler to pass the intercepted request to.
   *
   * @returns Observable of the intercepted request.
   */
  public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return this.authService.refreshSessionIfNeeded(request.url).pipe(switchMap(() => {
      const userSession = this.sessionService.getUserSession();
      const accessToken = userSession?.getAccessToken();

      request = request.clone({
        headers: accessToken ? request.headers.set('Authorization', accessToken) : request.headers
      });

      return next.handle(request);
    }));
  }
}
