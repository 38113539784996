import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { catchError, from, map, of } from 'rxjs';
import { LoggingService } from '@newroom-connect/library/services/logging';
import { SessionService } from '@newroom-connect/library/services/session';

export const AlredyLoggedInGuard: CanActivateFn = () => {
  const router = inject(Router);
  const sessionService = inject(SessionService);
  const loggingService = inject(LoggingService);

  const userSession = sessionService.getUserSession();

  let isLoggedIn = false;

  try {
    isLoggedIn = userSession?.isValid() ?? false;
  } catch (error) {
    loggingService.error(error);
  }

  if (isLoggedIn) {
    return from(router.navigate(['/home'])).pipe(
      catchError(error => {
        loggingService.error(error);
        return of(null);
      }),
      map(() => false)
    );
  }

  return of(!isLoggedIn);
};
