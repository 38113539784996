@if (!hasLoadedData) {
  <div class="w-full h-full flex justify-center items-center">
    <nrc-loading-spinner />
  </div>
}

<img
  class="w-full h-full"
  [ngClass]="{
    'bg-transparent-dark': !isTransparent,
    'hidden': !hasLoadedData,
    'object-cover': objectFit === 'cover',
    'object-contain': objectFit === 'contain'
  }"
  [src]="source.uri"
  [alt]="''"
  (load)="hasLoadedData = true"
/>
