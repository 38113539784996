<div class="flex flex-col space-y-1">
  <!-- Breadcrumb -->
  <div class="bg-[#141414] flex px-3 py-1 rounded-md grow">
    <div class="flex justify-between items-center grow">
      <div class="inline-flex items-center justify-center space-x-1 md:space-x-3">
        <div class="inline-flex items-center">
          <a href="#" tabindex="0" class="inline-flex items-center text-sm text-white" (click)="selectOption(null)" (keyup)="null">
            {{ label }}
          </a>
        </div>

        <div>
          <div class="flex items-center min-w-[128px]">
            <nrc-icon
              class="w-2 h-2 -rotate-90"
              tabindex="0"
              [name]="'select'"
            />

            @if (selectedOption) {
              <div class="flex items-center">
                <nrc-icon
                  class="w-3 h-3 ml-2"
                  [name]="'optionCircle'"
                  [fillColor]="'cyan'"
                />
              </div>
            }

            <a tabindex="0" class="ml-1 text-sm text-white md:ml-2" (click)="showOptions = true" (keyup)="null">
              @if (showOptions) {
                <div>
                  <input
                    type="text"
                    class="w-20 h-5 text-sm bg-transparent border-transparent focus:border-transparent outline-none focus:outline-none p-0 placeholder:text-white focus:ring-0"
                    [formControl]="searchInputFormControl"
                    [placeholder]="placeholder"
                  />
                </div>
              } @else {
                <div>
                  {{ (selectedOption | extractProperty: labelProperty) ?? placeholder }}
                </div>
              }
            </a>
          </div>
        </div>

        <div>
          @if (selectedOption && lastPartOfRoute()) {
            <div class="flex items-center">
              <nrc-icon
                class="w-2 h-2 -rotate-90"
                tabindex="0"
                [name]="'select'"
              />

              <span class="max-w-[80px] text-sm text-white/70 ml-1 md:ml-2 whitespace-nowrap overflow-hidden text-ellipsis">
                {{ lastPartOfRoute() | titlecase }}
              </span>
            </div>
          }
        </div>
      </div>

      <nrc-icon
        class="w-3 h-3 !ml-3 cursor-pointer"
        tabindex="0"
        [name]="'select'"
        [classList]="showOptions ? ['transition-transform', 'rotate-180'] : ['transition-transform']"
        (click)="showOptions = !showOptions"
        (keyup)="null"
      />
    </div>
  </div>

  <!-- The collapsible options menu of the selection -->
  @if (showOptions) {
    <div
      class="w-full max-h-48 bg-neutral-900 overflow-auto rounded z-10"
      [ngClass]="{
        'flex justify-center items-center': options.length === 0
      }"
      [@growAnimation]="{
        value: '', params: {
          heightStart: '0',
          heightEnd: '80px'
        }
      }"
    >
      @if (options.length === 0) {
        <nrc-loading class="relative my-8" />
      }

      @if (optionsFiltered.length > 0) {
        @for (option of optionsFiltered; track $index) {
          <div
            class="group relative flex items-center text-xs/[14px] text-[#f8f8f8] transition-[color] duration-250 ease-in-out px-4 py-2 cursor-pointer hover:bg-white/5"
            tabindex="0"
            aria-hidden="true"
            (click)="selectOption(option)"
          >
            <span class="cursor-pointer">
              {{ option | extractProperty: labelProperty }}
            </span>
          </div>
        }
      }
    </div>
  }
</div>
