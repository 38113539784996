import { Injectable, Signal, signal } from '@angular/core';
import { ISystemMessage } from '@newroom-connect/library/interfaces/system-message';

@Injectable({
  providedIn: 'root'
})
export class AlertService {
  public message = signal<ISystemMessage | null>(null);

  /**
   * Watch the current alert message.
   *
   * @returns The current alert message as read-only signal.
   */
  public watchMessage(): Signal<ISystemMessage | null> {
    return this.message.asReadonly();
  }

  /**
   * Set the alert message.
   * After a specified timeout, reset the alert message, if a reset timeout is specified.
   *
   * @param message The message to set.
   * @param resetTimeoutInMs The timeout to reset the alert message.
   */
  public setMessage(message: ISystemMessage, resetTimeoutInMs?: number): void {
    this.message.set(message);

    if (resetTimeoutInMs !== null && resetTimeoutInMs !== undefined) {
      setTimeout(() => {
        this.message.set(null);
      }, resetTimeoutInMs);
    }
  }

  /**
   * Reset the alert message to am empty object.
   */
  public resetMessage(): void {
    this.setMessage({ message: '' }, 0);
  }
}
