import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ToStringPipe } from '@newroom-connect/library/pipes/to-string';
import { ActionRole } from '@newroom-connect/library/enums/action-role';

export interface IChipConfiguration {
  actionRole?: ActionRole;
  isClosable?: boolean;
}

@Component({
  standalone: true,
  imports: [
    CommonModule,
    ToStringPipe
  ],
  selector: 'nrc-chip',
  templateUrl: './chip.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChipComponent {
  @Input({ required: true }) public label: unknown = '';
  @Input() public config?: IChipConfiguration;

  @Output() public closeEvent = new EventEmitter<void>();

  public actionRole = ActionRole;
}
