import { ChangeDetectionStrategy, Component, EventEmitter, Output, Signal, computed } from '@angular/core';
import { ActionRole } from '@newroom-connect/library/enums/action-role';
import { JwtHelper } from '@newroom-connect/library/helpers/jwt';
import { SessionService } from '@newroom-connect/library/services/session';

@Component({
  selector: 'nrc-virtual-studio-profile-tooltip',
  templateUrl: './profile-tooltip.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProfileTooltipComponent {
  @Output() public logoutEvent = new EventEmitter<void>();

  public firstName: Signal<string>;
  public lastName: Signal<string>;
  public fullName: Signal<string>;
  public email: Signal<string>;

  public actionRole = ActionRole;

  /**
   * @constructor
   *
   * @param sessionService
   */
  constructor(
    private readonly sessionService: SessionService
  ) {
    this.firstName = computed(() => JwtHelper.getValueFromToken('profile:firstName', this.sessionService.watchUserSession()()?.getIdToken()) ?? '');

    this.lastName = computed(() => JwtHelper.getValueFromToken('profile:lastName', this.sessionService.watchUserSession()()?.getIdToken()) ?? '');

    this.fullName = computed(() => {
      const idToken = this.sessionService.watchUserSession()()?.getIdToken();

      return `${JwtHelper.getValueFromToken('profile:firstName', idToken)} ${JwtHelper.getValueFromToken('profile:lastName', idToken)}`;
    });

    this.email = computed(() => {
      const idToken = this.sessionService.watchUserSession()()?.getIdToken();

      if (idToken) {
        const idTokenDecoded = JwtHelper.decodeToken<Record<string, string>>(idToken);

        return idTokenDecoded['email'];
      }

      return '';
    });
  }
}
