import { Injectable } from '@angular/core';
import { Observable, catchError, filter, map, throwError } from 'rxjs';
import { WebSocketSubject, webSocket } from 'rxjs/webSocket';
import { LoggingService } from '@newroom-connect/library/services/logging';
import { RxJSHelper } from '@newroom-connect/library/helpers/rxjs';
import { IWebsocketMessage } from '@newroom-connect/library/interfaces/websocket-message';

@Injectable({
  providedIn: 'root'
})
export class WebsocketService {
  private socket$?: WebSocketSubject<IWebsocketMessage<unknown>>;

  /**
   * @constructor
   *
   * @param loggingService
   */
  constructor(
    private readonly loggingService: LoggingService
  ) {}

  /**
   * Initialize the websocket service where the socket is opened based on the given URL.
   *
   * @param websocketUrl
   */
  public initialize(websocketUrl: string): void {
    this.socket$ = webSocket<IWebsocketMessage<unknown>>(websocketUrl);
  }

  /**
   * Watch Websocket messages from a specific topic.
   *
   * @param topic The topic to watch messages from.
   *
   * @returns Observable for watching messages of a topic.
   *
   * @throws Error, if websocket service is not initialized (socket not set).
   */
  public watchTopic<T>(topic: string): Observable<IWebsocketMessage<T>> {
    if (!this.socket$) {
      throw new Error('Unable to watch topic: Websocket service is not initialized. Please call "initialize()" first.');
    }

    return this.socket$.pipe(
      RxJSHelper.retryBackoff(),
      catchError(error => {
        this.loggingService.error('Socket subscription failed: ', error);

        return throwError(() => error);
      }),
      map(message => message as IWebsocketMessage<T>),
      filter(message => !!(message.topic && typeof message.topic === 'string' && message.topic.startsWith(topic)))
    );
  }
}
