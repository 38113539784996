import { Component, OnInit, Signal, effect, signal } from '@angular/core';
import { ChildrenOutletContexts, NavigationStart, Router } from '@angular/router';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { Observable, catchError, finalize, of } from 'rxjs';
import { routeFadeAnimation } from '@newroom-connect/library/animations/route-fade';
import { fadeInOutAnimation } from '@newroom-connect/library/animations/fade-in-out';
import { ISystemMessage } from '@newroom-connect/library/interfaces/system-message';
import { IProject } from '@newroom-connect/library/interfaces/project';
import { StateService } from '@newroom-connect/library/services/state';
import { NetworkService } from '@newroom-connect/library/services/network';
import { TranslationService } from '@newroom-connect/library/services/translation';
import { AlertService } from '@newroom-connect/library/services/alert';
import { ToastService } from '@newroom-connect/library/services/toast';
import { AuthService } from '@newroom-connect/library/services/auth';
import { LoggingService } from '@newroom-connect/library/services/logging';
import { ProjectService } from '@newroom-connect/library/services/project';
import { Level } from '@newroom-connect/library/enums/level';

import { environment } from '../../../../environments/environment';
import packageInfo from '../../package.json';

@Component({
  selector: 'nrc-virtual-studio-root',
  templateUrl: './app.component.html',
  animations: [routeFadeAnimation, fadeInOutAnimation]
})
export class AppComponent implements OnInit {
  private static readonly SHOW_LOGO_URLS = [
    '/signin',
    '/signup',
    '/404'
  ];

  public title = 'Newroom Connect Virtual Studio';
  public appVersion = packageInfo['version'];
  public isProdEnvironment = environment.production;

  public isLoading$: Observable<boolean>;
  public alertMessage = signal<ISystemMessage | null>(null).asReadonly();
  public toastMessage = signal<ISystemMessage | null>(null).asReadonly();
  public currentProject = signal<IProject | null>(null).asReadonly();
  public showLogo = signal<boolean>(false);

  private routerEventsSig: Signal<unknown>;

  /**
   * @constructor
   *
   * @param router
   * @param alertService
   * @param toastService
   * @param contexts
   * @param authService
   * @param stateService
   * @param networkService
   * @param translationService
   * @param loggingService
   * @param projectService
   */
  constructor(
    public readonly router: Router,
    public readonly alertService: AlertService,
    public readonly toastService: ToastService,
    private readonly contexts: ChildrenOutletContexts,
    private readonly authService: AuthService,
    private readonly stateService: StateService,
    private readonly networkService: NetworkService,
    private readonly translationService: TranslationService,
    private readonly loggingService: LoggingService,
    private readonly projectService: ProjectService
  ) {
    this.isLoading$ = this.stateService.watchLoadingState().pipe(takeUntilDestroyed());

    this.routerEventsSig = toSignal(this.router.events);

    effect(() => {
      const routerEvent = this.routerEventsSig();

      if (routerEvent instanceof NavigationStart) {
        // Check by route URL, if the logo should be shown.
        this.showLogo.set(AppComponent.SHOW_LOGO_URLS.some(url => routerEvent.url.startsWith(url)));
      }
    }, { allowSignalWrites: true });

    // Watch the network status and display an error message, if the network is unreachable.
    effect(() => {
      const status = this.networkService.watchNetworkStatus()();

      if (!status.isOnline) {
        this.alertService.setMessage({
          headline: this.translationService.translate('ERROR.NETWORK_UNREACHABLE.HEADLINE'),
          message: this.translationService.translate('ERROR.NETWORK_UNREACHABLE.MESSAGE'),
          level: Level.ERROR
        });
      }
    }, { allowSignalWrites: true });
  }

  /**
   *
   */
  public ngOnInit(): void {
    this.alertMessage = this.alertService.watchMessage();
    this.toastMessage = this.toastService.watchMessage();
    this.currentProject = this.projectService.watchCurrentProject();
  }

  /**
   *
   * @returns
   */
  public getRouteAnimationData(): string {
    return this.contexts.getContext('primary')?.route?.snapshot?.data?.['animation'];
  }

  /**
   * Handle a logout event triggered by the user.
   */
  public handleLogout(): void {
    this.stateService.setLoadingState(true);

    this.authService.logout().pipe(
      catchError(() => this.handleLogoutError()),
      finalize(() => this.stateService.setLoadingState(false))
    ).subscribe(() => {
      this.router.navigate(['/signin']).catch(error => this.loggingService.error('Unable to navigate to signin page: ', error));
    });
  }

  /**
   *
   * @returns
   */
  private handleLogoutError(): Observable<void> {
    this.alertService.setMessage({
      headline: this.translationService.translate('ERROR.LOGOUT_FAILED.HEADLINE'),
      message: this.translationService.translate('ERROR.LOGOUT_FAILED.MESSAGE')
    });

    return of();
  }
}
