import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, concatMap, map, of, tap } from 'rxjs';
import { ApiService } from '@newroom-connect/library/services/api';
import { LoggingService } from '@newroom-connect/library/services/logging';
import { SessionService } from '@newroom-connect/library/services/session';
import { StateService } from '@newroom-connect/library/services/state';
import { JwtError } from '@newroom-connect/library/errors/jwt';
import { ITokenSet } from '@newroom-connect/library/interfaces/token-set';
import { JwtHelper } from '@newroom-connect/library/helpers/jwt';

export interface IEmailInput {
  email: string;
}

export interface ILoginInput extends IEmailInput {
  password: string;
  rememberMe?: boolean;
}

export interface ILogoutInput {
  accessToken: string;
}

export interface IProfileInput {
  gender: string;
  firstName: string;
  lastName: string;
  company: string;
  purpose: string;
  country: string;
}

export interface IRegistrationInput extends IEmailInput {
  password: string;
  profile: IProfileInput;
}

export interface IConfirmRegistrationInput {
  username: string;
  confirmationCode: string;
}

export interface IResetPasswordInput {
  username: string;
  verificationCode: string;
  newPassword: string;
}

@Injectable({
  providedIn: 'root'
})
export class AuthService extends ApiService {
  /**
   * @constructor
   *
   * @param http
   * @param loggingService
   * @param sessionService
   * @param stateService
   */
  constructor(
    protected override readonly http: HttpClient,
    protected override readonly loggingService: LoggingService,
    private readonly sessionService: SessionService,
    private readonly stateService: StateService
  ) {
    super(http, loggingService);
  }

  /**
   * Login to the system with the given input.
   * On successful login, the received tokens will be stored in the local storage.
   *
   * @param input
   *
   * @returns Observable containing the requested tokens.
   */
  public login(input: ILoginInput): Observable<ITokenSet> {
    return this.post<ITokenSet>('authenticate/login', input, { withCredentials: true }).pipe(
      tap({ next: (tokenSet) => this.sessionService.setUserSession(tokenSet, input.rememberMe) })
    );
  }

  /**
   *
   * @returns
   */
  public logout(): Observable<void> {
    return this.post<void>('authenticate/logout', { withCredentials: true }).pipe(
      tap({ complete: () => this.sessionService.clearUserSession() })
    );
  }

  /**
   *
   * @param input
   *
   * @returns
   */
  public register(input: IRegistrationInput): Observable<any> {
    return this.post('register', input);
  }

  /**
   *
   * @param input
   *
   * @returns
   */
  public confirmRegistration(input: IConfirmRegistrationInput): Observable<any> {
    return this.post('register/confirm/code', input);
  }

  /**
   *
   * @param input
   *
   * @returns
   */
  public resendConfirmation(input: IEmailInput): Observable<any> {
    return this.post('register/confirm/resend', input);
  }

  /**
   * Login to the system with the given input.
   * On successful login, the received tokens will be stored in the local storage.
   *
   * @param input The login input.
   *
   * @returns Observable containing the requested tokens.
   */
  public forgotPassword(input: IEmailInput): Observable<any> {
    return this.post<any>('authenticate/password/request', input);
  }

  /**
   * Login to the system with the given input.
   * On successful login, the received tokens will be stored in the local storage.
   *
   * @param input The login input.
   *
   * @returns Observable containing the requested tokens.
   */
  public resetPassword(input: IResetPasswordInput): Observable<any> {
    return this.post<any>('authenticate/password/reset', input);
  }

  /**
   * Refresh the current user session.
   * On successful refresh, the refreshed tokens will be stored in the local storage.
   *
   * @returns Observable containing the requested tokens.
   *
   * @throws `JwtError` if the current ID token is not able to be decoded.
   */
  public refreshSession(): Observable<ITokenSet> {
    const userSession = this.sessionService.getUserSession();

    if (!userSession) {
      throw new Error('Unable to refresh session: No user session available');
    }

    const idToken = userSession.getIdToken();
    const refreshToken = userSession.getRefreshToken();

    let idTokenDecoded: Record<string, string>;

    try {
      idTokenDecoded = JwtHelper.decodeToken(idToken ?? '');
    } catch (error) {
      throw new JwtError(error as Error);
    }

    return this.post<ITokenSet>('authenticate/session/refresh', { username: idTokenDecoded['profile:userId'], refreshToken }).pipe(
      tap({ next: (tokenSet) => this.sessionService.setUserSession(tokenSet, this.sessionService.getUserSession()?.getRememberMe()) })
    );
  }

  /**
   * Refresh the current user session if the session needs a refresh.
   *
   * @param requestUrl An optional URL of the request to determine, if session request is even needed by checking the URL.
   *
   * @returns Observable performing the session refresh.
   */
  public refreshSessionIfNeeded(requestUrl?: string): Observable<ITokenSet | undefined> {
    // Do not refresh the session if the request will do it by itself.
    const isSessionRefreshRequest = requestUrl?.endsWith('authenticate/session/refresh');

    if (isSessionRefreshRequest) {
      return of(undefined);
    }

    const userSession = this.sessionService.getUserSession();

    // If the user session needs a refresh, set an app-wide loading state, refresh the session and disable the loading state.
    if (userSession?.needsRefresh()) {
      return of(this.stateService.setLoadingState(true)).pipe(
        concatMap(() => this.refreshSession()),
        map(tokenSet => {
          this.stateService.setLoadingState(false);

          return tokenSet;
        })
      );
    }

    return of(undefined);
  }
}
