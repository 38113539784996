import { ChangeDetectionStrategy, Component } from '@angular/core';
import { LoadingSpinnerComponent } from '@newroom-connect/library/components/loading/loading-spinner';

@Component({
  standalone: true,
  imports: [LoadingSpinnerComponent],
  selector: 'nrc-loading',
  templateUrl: './loading.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoadingComponent {}
